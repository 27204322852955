import gala from 'assets/gala.jpeg'
import frekwencja from 'assets/frekwencja.png'
import campaign from 'assets/campaign.png'
import akcja from 'assets/Akcja dla Ukrainy.png'
import barometr from 'assets/Barometr_Bezpieczenstwa_Prawnego.png'
import nagroda from 'assets/Nagroda_PRB.jpeg'
import campus from 'assets/Campus_Polska_Przyszlosci.jpeg'
import fundacja from 'assets/Fundacja_Karskiego.png'
import rocznica from 'assets/20lat.png'
import powodzianie from 'assets/FPRB_POWODZIANIE.png'
import women from 'assets/women.jpeg'

export const temporaryArticles =
  [
  {
      date: '22 września 2024',
      title: 'Fundacja PRB z pomocą dla powodzian',
      image: powodzianie,
      content: 'Fundacja Polskiej Rady Biznesu uruchomiła zbiórkę na pomoc dla powodzian. W ciągu pierwszych dwóch dni zebrano ponad dwa miliony złotych. Środki są obecnie wydawane na pokrycie najpilniejszych potrzeb związanych z naprawą szkód wyrządzonych przez powódź. Oprócz wsparcia finansowego, Fundacja koordynuje też pomoc bezpośrednią i materialno-rzeczową, której wartość również przekroczyła dwa miliony złotych.<br/><br/>Osoby zainteresowane wsparciem zbiórki mogą dokonywać wpłat na konto Fundacji PRB z dopiskiem „Dla powodzian”:<br/><br/>Fundacja Polskiej Rady Biznesu<br> Aleja Jana Chrystiana Szucha 17, 00-580<br/>Warszawa, Polska<br/>Nr konta: 12 1140 1010 0000 3867 8100 1001<br/>KRS: 0000703640<br/>NIP: 1132955601<br/>W przypadku woli przekazania większej kwoty (powyżej 10 tys. zł) prosimy o kontakt z Fundacją: <a href="mailto:fundacja@prb.pl">fundacja@prb.pl</a>',
      linkName: '',
      link: ''
    },
    {
      date: '5 września 2024',
      title: 'Kampania „To Twój wybór” z brązem w prestiżowym międzynarodowym konkursie',
      image: women,
      content: '„To Twój wybór” – kampania profrekwencyjna sfinansowana przez Fundację PRB została wraz kampanią „Kobiety na wybory” zgłoszona w międzynarodowym konkursie Gerety Awards 2024 pod wspólnym szyldem Women Against Populists. Kampanie otrzymały brązowy medal w kategorii Work for Good Cut. Gratulacje dla twórczyń i twórców!',
      linkName: 'Więcej',
      link: 'https://www.geretyawards.com/winners'
    },
    {
      date: '18 lipca 2024',
      title: '#20latPLwUE – projekt Fundacji PRB i Fundacji Batorego',
      image: rocznica,
      content: '120 firm, organizacji społecznych i mediów świętowało razem z nami 20 lat Polski w Unii Europejskiej. Umieszczając pin „20latPLwUE” na stronach internetowych, w aplikacjach, oznaczając zdjęcie profilowe w mediach społecznościowych, a nawet budynki firm, uczestnicy pokazywali przywiązanie do wartości europejskich. Udział w akcji wzięło wiele wiodących marek, m.in.: InPost, Grupa Żabka, Amica, Erbud, Maspex, PZU, Tymbark, Kotlin, Kazar, Adamed, Grupa Diagnostyka, Pracuj.pl, czy InterCars.',
      linkName: 'Zobacz film',
      link: 'https://youtu.be/Jo8YI9NwRDc'
    },
    {
      date: '10 stycznia 2024',
      title: 'Raport: Fenomen wyborczej mobilizacji. Przyczyny rekordowej frekwencji podczas wyborów parlamentarnych 2023 – wnioski z badań',
      image: frekwencja,
      content: 'Na zlecenie Fundacjo Batorego przeprowadzone zostały badania nt. rekordowej frekwencji podczas ostatnich wyborów parlamentarnych.  Badania pokazały, że fakt styczności z działaniami profrekwencyjnymi przełożył się na udział w wyborach. Aż 78 proc. respondentów, którzy mieli styczność z kampaniami uważa, że zachęciły one do pójścia na wybory. Wśród głosujących 42 proc. twierdzi, że widziało niepartyjne reklamy i inne działania profrekwencyjne. Fundacja PRB wsparła 8 takich kampanii. Raport współfinansowany był przez Fundację PRB, Fundację Batorego i Philanthropy for Impact.',
      linkName: 'Zobacz raport',
      link: 'https://www.batory.org.pl/wp-content/uploads/2024/01/Fenomen.wyborczej.moblizacji_RAPORT.pdf'
    },
    {
      date: '6 grudnia 2023',
      title: 'Sukces kampanii profrekwencyjnych!',
      image: campaign,
      content: 'Komunikat PRB o skuteczności kampanii profrekwencyjncych, o ich zasięgu i wpływie na postrzeganie wyborów opublikował TVN24. – Pojawiła się nowa, bardzo zauważalna norma społeczna (…). Hasła kampanii nie namawiały do głosowania na konkretne ugrupowania, ale widać, że zmieniły myślenie o udziale w wyborach. Że to nie tylko przywilej czy obowiązek, ale przede wszystkim odpowiedzialność i poczucie sprawczości – podkreśliła dr Małgorzata Durska, prezeska Fundacji PRB.',
      linkName: 'Więcej w materiale TVN24',
      link: 'https://tvn24.pl/biznes/z-kraju/wybory-2023-sukces-kampanii-profrekwencyjnych-komunikat-polskiej-rady-biznesu-st7518919'
    },
    {
      date: '14 listopada 2023',
      title: 'Gala Fundacyjna PRB',
      image: gala,
      content: 'Pod hasłem: Wygraliśmy wspólnie – i to jest piękne! świętowaliśmy wysoką frekwencję wyborczą w wyborach parlamentarnych. Spotkaliśmy się z darczyńcami, inicjatorami kampanii i przedstawicielami organizacji i firm, które zaangażowane były w realizację wspólnych 8 projektów. Naszymi gośćmi były również zaprzyjaźnione środowiska NGO i organizacje biznesowe.',
      linkName: '',
      link: 'www.trojmiasto.pl'
    },
    {
      date: '7 grudnia 2022',
      title: 'Wsparcie obrony Ukrainy Akcja Marszałka Senatu RP i Polskiej Rady Biznesu',
      image: akcja,
      content: '6 grudnia z inicjatywy Senatu RP przy współpracy z Polską Radą Biznesu z Lublina wyruszył transport dwóch tirów z butami zimowymi dla walczących Ukraińców. Zakup i transport zostały w całości sfinansowane przez członków PRB.\\n\\nW konferencji prasowej poprzedzającej wysłanie transportu wzięli udział Marszałek Senatu RP prof. Tomasz Grodzki, prezes PRB i członek Rady Fundacji PRB Wojciech Kostrzewa, przewodniczący Rady Gospodarczej przy Marszałku Senatu RP i wiceprezes PRB Zbigniew Jakubas, Wicemarszałek Senatu Michał Tomasz Kamiński oraz senator Krzysztof Kwiatkowski.',
      linkName: 'Zobacz konferencję prasową',
      link: 'https://www.youtube.com/watch?v=WCkxxKNt8dk'
    },
    {
      date: '14 października 2022',
      title: '„Barometr Bezpieczeństwa Prawnego Przedsiębiorców”: Fundacja Court Watch Polska ze wsparciem Fundacji PRB',
      image: barometr,
      content: 'Fundacja Court Watch Polska to pozarządowy i niezależny ośrodek strażniczy i think-tank specjalizujący się w działalności edukacyjnej, analitycznej i naukowej w dziedzinie prawa, praworządności, wymiaru sprawiedliwości i poprawy świadomości prawnej społeczeństwa. Misją Fundacji Court Watch Polska jest zmienianie polskiego sądownictwa na lepsze. Fundacja prowadzi m.in. Obywatelski Monitoring Sądów, przyznaje co roku tytuł „Obywatelskiego Sędziego Roku”.\\n\\nDotacja Fundacji PRB została przekazana na realizację projektu „Barometr Bezpieczeństwa Prawnego Przedsiębiorstw”. Barometr jest cyklicznym badaniem opinii przedsiębiorców na temat bezpieczeństwa prawnego w Polsce. Badanie jest przeprowadzane od 2017 roku i obejmuje kilkuset przedsiębiorców z różnych branż i o różnej skali działalności. Kwartalne raporty zawierają nie tylko precyzyjną analizę przyczyn problemów inwestorów, ale również zawierają rekomendacje, które kierowane są do władzy ustawodawczej, wykonawczej i sądowniczej.',
      linkName: 'Więcej',
      link: 'https://courtwatch.pl/projekty/projekty-biezace/barometr-bezpieczenstwa-prawnego/'
    },
    {
      date: '12 września 2022',
      title: 'Gala 11. edycji Nagrody Polskiej Rady Biznesu',
      image: nagroda,
      content: 'Nagroda Polskiej Rady Biznesu jest przyznawana od 2012 roku. Tym wyróżnieniem honorowane są osoby, które w znaczący sposób wpływają na rzeczywistość gospodarczą i społeczną w Polsce. Nominacje mogą zgłaszać zarówno instytucje, jak i osoby prywatne. Nagroda przyznawana jest w trzech kategoriach: Sukces, Wizja i Innowacje oraz Działalność Społeczna. Dodatkowo Kapituła Nagrody PRB przyznaje Nagrodę Specjalną. Kandydatów ocenia 24-osobowa Kapituła Nagrody PRB, w skład której wchodzą autorytety życia gospodarczego, naukowego, kulturalnego, Zarząd PRB, członkowie rodzin Patronów Nagrody, a także laureaci ubiegłorocznych edycji. Gala Nagrody PRB tradycyjnie odbywa się w Ufficio Primo i gromadzi ponad 500 znamienitych gości ze świata biznesu, polityki, kultury i mediów. Transmitowana jest bezpośrednio przez TVN24. Dotacja została przeznaczona na organizację konkursu i Gali Nagrody.',
      linkName: 'Więcej o Nagrodzie PRB',
      link: 'https://nagrodaprb.pl/'
    },
    {
      date: '2 września 2022',
      title: 'Fundacja PRB ufundowała stypendium na udział w leadership seminar w Waszyngtonie',
      image: fundacja,
      content: 'Misją Fundacji Edukacyjnej Jana Karskiego jest ukazanie współczesnego wymiaru spuścizny Karskiego: potrzeby najwyższych standardów służby publicznej, obrony praw człowieka i budowania relacji społecznych opartych na tolerancji i otwartości.\\n\\nGeorgetown Leadership Seminar w Waszyngtonie to wiodący program edukacyjny dla liderów prowadzony przez Institute for the Study of Diplomacy w Georgetown University. W ciągu ostatnich 36 lat ponad 1000 uczestników seminarium reprezentowało ponad 100 krajów.\\n\\nDotacja PRB została przekazana na pokrycie stypendium i wszelkich dodatkowych kosztów związanych z uczestnictwem Ganny Bilokin wytypowanej przez Fundację Edukacyjną Jana Karskiego do uczestnictwa w Georgetown Leadership Seminar w 2022 roku. Ganna Bilokin jest szefową wydziału współpracy i stosunków międzynarodowych w ratuszu w Kijowie. Pierwsze miesiące wojny spędziła w Polsce, współorganizując z władzami samorządowymi w Warszawie i Krakowie pomoc humanitarną dla Ukrainy oraz transfer ukraińskich kobiet z niepełnosprawnymi dziećmi do Francji i Szwajcarii.',
      linkName: '',
      link: ''
    },
    {
      date: '22 lipca 2022',
      title: 'Fundacja PRB wspiera Campus Polska Przyszłości 2022',
      image: campus,
      content: 'Campus Polska Przyszłości to coroczne, tygodniowe wydarzenie dla młodych, aktywnych osób, które chcą mieć wpływ na to, w jakim kierunku będzie rozwijała się Polska w następnych latach i pokoleniach. W ramach Campusu odbywają się wykłady, warsztaty, panele dyskusyjne i spotkania z osobami, które chcą działać i zależy im na zmianie – ekspertami z różnych dziedzin, aktywistami, społecznikami, samorządowcami, publicystami, naukowcami i artystami. Udział w Campusie jest bezpłatny, a kwalifikacja przebiega w trybie otwartej rekrutacji.\\n\\nFundacja PRB wsparła organizację wydarzeń poświęconych edukacji ekonomicznej, promocji idei przedsiębiorczości i integracji Polski w strukturach Unii Europejskiej.',
      linkName: '',
      link: ''
    }
  ]
